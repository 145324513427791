import GeoJSON from 'ol/format/GeoJSON.js';
import {Vector as VectorLayer, Group as LayerGroup} from 'ol/layer.js';
import {Vector as VectorSource} from 'ol/source.js';
import {Fill, Stroke, Style} from 'ol/style.js';



////////////////////////////////////////////////////////////////////////
//CAPA DE GEOLOGÍA

var styleFunction_I_Geologia = function(feature, resolution) {
  return new  Style({
    stroke: Funct_StrokeStyle[feature.getProperties().LIT_NOMBRE],
    fill: Funct_FillStyle[feature.getProperties().LIT_NOMBRE]
  })
};


var vectorSource_I_Geologia = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_I_Geologia)
});

global.Layer_I_Geologia = new VectorLayer({
  title: 'Layer_I_Geologia',
  name: '02 Geología',
  type: 'Capa específica',
  source: vectorSource_I_Geologia,
  style: styleFunction_I_Geologia,
  zIndex:6
});

//Agrupamos todas las capas pertenecientes al mismo plano, para activarlas todas a la misma vez
global.Map_IA_04 = new LayerGroup({
  title:    'Map_IA_04',
  name:     'Geología',
  type:     'Capa específica',
  opacity:   0.75,
	visible:   false,
  layers:[
    Layer_I_Geologia
  ]
});
////////////////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////////
//CAPA DE GEOMORFOLOGÍA

var styleFunction_I_Geomorfologia = function(feature, resolution) {
  var ParametrosLabel = Funct_Label["Layer_I_Geomorfologia"];

  return new  Style({
    stroke: Funct_StrokeStyle[feature.getProperties().geomorfolo],
    text: createTextStyle(feature, resolution, ParametrosLabel[0],FunctLabelText(feature, ParametrosLabel[1]), ParametrosLabel[2], ParametrosLabel[3], ParametrosLabel[4], ParametrosLabel[5], ParametrosLabel[6], ParametrosLabel[7], ParametrosLabel[8], ParametrosLabel[9], ParametrosLabel[10], ParametrosLabel[11], ParametrosLabel[12], ParametrosLabel[13], ParametrosLabel[14], ParametrosLabel[15]),
    fill: Funct_FillStyle[feature.getProperties().geomorfolo]
  })
};

var vectorSource_I_Geomorfologia = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_I_Geomorfologia)
});

global.Layer_I_Geomorfologia = new VectorLayer({
  title: 'Layer_I_Geomorfologia',
  name: '01 Geomorfología',
  type: 'Capa específica',
  source: vectorSource_I_Geomorfologia,
  style: styleFunction_I_Geomorfologia,
  zIndex:6
});

//Agrupamos todas las capas pertenecientes al mismo plano, para activarlas todas a la misma vez
global.Map_IA_05 = new LayerGroup({
  title:    'Map_IA_05',
  name:     'Geomorfología',
  type:     'Capa específica',
  opacity:   0.75,
	visible:   false,
  layers:[
    Layer_I_Geomorfologia
  ]
});
////////////////////////////////////////////////////////////////////////




////////////////////////////////////////////////////////////////////////
//CAPA DE EDAFOLOGÍA

var styleFunction_I_Edafologia = function(feature, resolution) {
  return new  Style({
    stroke: Funct_StrokeStyle[feature.getProperties().TIPO],
    fill: Funct_FillStyle[feature.getProperties().TIPO]
  })
};


var vectorSource_I_Edafologia = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_I_Edafologia)
});

global.Layer_I_Edafologia = new VectorLayer({
  title: 'Layer_I_Edafologia',
  name: '01 Edafología',
  type: 'Capa específica',
  source: vectorSource_I_Edafologia,
  style: styleFunction_I_Edafologia,
  zIndex:6
});

//Agrupamos todas las capas pertenecientes al mismo plano, para activarlas todas a la misma vez
global.Map_IA_06 = new LayerGroup({
  title:    'Map_IA_06',
  name:     'Edafología',
  type:     'Capa específica',
  opacity:   0.75,
	visible:   false,
  layers:[
    Layer_I_Edafologia
  ]
});
////////////////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////////
//CAPA DE HIDROLOGÍA
//Capa de barrancos

var styleFunction_I_Barrancos = function(feature, resolution) {
  return new  Style({
    stroke: Funct_StrokeStyle[feature.getProperties().TIPO]
  })
};


var vectorSource_I_Barrancos = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_I_Barrancos)
});

global.Layer_I_Barrancos = new VectorLayer({
  title: 'Layer_I_Barrancos',
  name: '01 Barrancos',
  type: 'Capa específica',
  source: vectorSource_I_Barrancos,
  style: styleFunction_I_Barrancos,
  zIndex:8
});

//Capa de cuencas hidrográficas

var styleFunction_I_Cuencas = function(feature, resolution) {
  var ParametrosLabel = Funct_Label["Layer_I_Cuencas"];

  return new  Style({
    stroke: Funct_StrokeStyle[Symbolyze_Layer_I_Cuencas],
    fill: Funct_FillStyle[Symbolyze_Layer_I_Cuencas],
    text:  createTextStyle(feature, resolution, ParametrosLabel[0],FunctLabelText(feature, ParametrosLabel[1]), ParametrosLabel[2], ParametrosLabel[3], ParametrosLabel[4], ParametrosLabel[5], ParametrosLabel[6], ParametrosLabel[7], ParametrosLabel[8], ParametrosLabel[9], ParametrosLabel[10], ParametrosLabel[11], ParametrosLabel[12], ParametrosLabel[13], ParametrosLabel[14], ParametrosLabel[15]),
  })
};


var vectorSource_I_Cuencas = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_I_Cuencas)
});

global.Layer_I_Cuencas = new VectorLayer({
  title: 'Layer_I_Cuencas',
  name: 'F02 ' + Symbolyze_Layer_I_Cuencas,
  type: 'Capa específica',
  source: vectorSource_I_Cuencas,
  style: styleFunction_I_Cuencas,
  zIndex:9
});


var styleFunction_I_MAS = function(feature, resolution) {
  return new  Style({
    stroke: Funct_StrokeStyle[feature.getProperties().Nombre],
    fill: Funct_FillStyle[feature.getProperties().Nombre]
  })
};


var vectorSource_I_MAS = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_I_MAS)
});

global.Layer_I_MAS = new VectorLayer({
  title: 'Layer_I_MAS',
  name: '03 Masas de agua subterránea',
  type: 'Capa específica',
  source: vectorSource_I_MAS,
  style: styleFunction_I_MAS,
  zIndex:6
});

//Agrupamos todas las capas pertenecientes al mismo plano, para activarlas todas a la misma vez
global.Map_IA_07 = new LayerGroup({
  title:    'Map_IA_07',
  name:     'Hidrología',
  type:     'Capa específica',
  opacity:   0.75,
	visible:   false,
  layers:[
    Layer_I_Barrancos,
    Layer_I_Cuencas,
    Layer_I_MAS
  ]
});
////////////////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////////
//CAPA DE VEGETACIÓN

var styleFunction_I_Vegetacion = function(feature, resolution) {
  return new  Style({
    stroke: Funct_StrokeStyle[feature.getProperties().Nombre],
    fill: Funct_FillStyle[feature.getProperties().Nombre]
  })
};


var vectorSource_I_Vegetacion = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_I_Vegetacion)
});

global.Layer_I_Vegetacion = new VectorLayer({
  title: 'Layer_I_Vegetacion',
  name: '01 Vegetación',
  type: 'Capa específica',
  source: vectorSource_I_Vegetacion,
  style: styleFunction_I_Vegetacion,
  zIndex:3
});

//Agrupamos todas las capas pertenecientes al mismo plano, para activarlas todas a la misma vez
global.Map_IA_08 = new LayerGroup({
  title:    'Map_IA_08',
  name:     'Vegetación',
  type:     'Capa específica',
  opacity:   0.75,
	visible:   false,
  layers:[
    Layer_I_Vegetacion
  ]
});
////////////////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////////
//CAPA DE CAPACIDAD AGROLÓGICA

var styleFunction_I_CAP_AGRO = function(feature, resolution) {
  return new  Style({
    stroke: Funct_StrokeStyle[feature.getProperties().CAP_AGROL],
    fill: Funct_FillStyle[feature.getProperties().CAP_AGROL]
  })
};


var vectorSource_I_CAP_AGRO = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_I_CAP_AGRO)
});

global.Layer_I_CAP_AGRO = new VectorLayer({
  title: 'Layer_I_CAP_AGRO',
  name: '01 Capacidad agrológica',
  type: 'Capa específica',
  source: vectorSource_I_CAP_AGRO,
  style: styleFunction_I_CAP_AGRO,
  zIndex:3
});

//Agrupamos todas las capas pertenecientes al mismo plano, para activarlas todas a la misma vez
global.Map_IA_09 = new LayerGroup({
  title:    'Map_IA_09',
  name:     'Capacidad agrológica',
  type:     'Capa específica',
  opacity:   0.75,
	visible:   false,
  layers:[
    Layer_I_CAP_AGRO
  ]
});
////////////////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////////
//CAPA DE PATRIMONIO CULTURAL

var styleFunction_I_PC_Etno_Point = function(feature, resolution) {
  return new  Style({
    image: Funct_ImageStyle[feature.getProperties().LAYER]
  })
};


var vectorSource_I_PC_Etno_Point = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_I_PC_Etno_Point)
});

global.Layer_I_PC_Etno_Point = new VectorLayer({
  title: 'Layer_I_PC_Etno_Point',
  name: '01 Patrimonio etnográfico (Carta Etnográfica de Gran Canaria)',
  type: 'Capa específica',
  source: vectorSource_I_PC_Etno_Point,
  style: styleFunction_I_PC_Etno_Point,
  zIndex:9
});


var styleFunction_I_PC_Carta_Arqueo = function(feature, resolution) {
  return new  Style({
    stroke: Funct_StrokeStyle[Symbolyze_Layer_I_PC_Carta_Arqueo],
    fill: Funct_FillStyle[Symbolyze_Layer_I_PC_Carta_Arqueo]
  })
};


var vectorSource_I_PC_Carta_Arqueo = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_I_PATR_ARQUEO)
});

global.Layer_I_PC_Carta_Arqueo = new VectorLayer({
  title: 'Layer_I_PC_Carta_Arqueo',
  name: 'F02 ' + Symbolyze_Layer_I_PC_Carta_Arqueo,
  type: 'Capa específica',
  opacity:   0.75,
  source: vectorSource_I_PC_Carta_Arqueo,
  style: styleFunction_I_PC_Carta_Arqueo,
  zIndex:9
});

//Agrupamos todas las capas pertenecientes al mismo plano, para activarlas todas a la misma vez
global.Map_IA_10 = new LayerGroup({
  title:    'Map_IA_10',
  name:     'Patrimonio cultural',
  type:     'Capa específica',
  opacity:   0.75,
	visible:   false,
  layers:[
    Layer_I_PC_Etno_Point,
    Layer_I_PC_Carta_Arqueo
  ]
});
////////////////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////////
//CAPA DE RIESGOS

var styleFunction_I_RIES_Point = function(feature, resolution) {
  return new  Style({
    image: Funct_ImageStyle[feature.getProperties().RIE_NOM]
  })
};


var vectorSource_I_RIES_Point = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_I_RIE_Point)
});

global.Layer_I_RIES_Point = new VectorLayer({
  title: 'Layer_I_RIES_Point',
  name: '01 Riesgos',
  type: 'Capa específica',
  source: vectorSource_I_RIES_Point,
  style: styleFunction_I_RIES_Point,
  zIndex:9
});


var styleFunction_I_RIES_Line = function(feature, resolution) {
  return new  Style({
    stroke: Funct_StrokeStyle[feature.getProperties().RIE_NOM]
  })
};


var vectorSource_I_RIES_Line = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_I_RIE_Line)
});

global.Layer_I_RIES_Line = new VectorLayer({
  title: 'Layer_I_RIES_Line',
  name: '01 Riesgos',
  type: 'Capa específica',
  source: vectorSource_I_RIES_Line,
  style: styleFunction_I_RIES_Line,
  zIndex:8
});


var styleFunction_I_RIES_Poly = function(feature, resolution) {
  return new  Style({
    stroke: Funct_StrokeStyle[feature.getProperties().RIE_NOM],
    fill: Funct_FillStyle[feature.getProperties().RIE_NOM]
  })
};


var vectorSource_I_RIES_Poly = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_I_RIE_Poly)
});

global.Layer_I_RIES_Poly = new VectorLayer({
  title: 'Layer_I_RIES_Poly',
  name: '01 Riesgos',
  type: 'Capa específica',
  source: vectorSource_I_RIES_Poly,
  style: styleFunction_I_RIES_Poly,
  zIndex:3
});


//Agrupamos todas las capas pertenecientes al mismo plano, para activarlas todas a la misma vez
global.Map_IA_11 = new LayerGroup({
  title:    'Map_IA_11',
  name:     'Riesgos',
  type:     'Capa específica',
  opacity:   0.75,
	visible:   false,
  layers:[
    Layer_I_RIES_Point,
    Layer_I_RIES_Line,
    Layer_I_RIES_Poly
  ]
});
////////////////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////////
//CAPA DE IMPACTOS

var styleFunction_I_IMP_Point = function(feature, resolution) {
  return new  Style({
    image: Funct_ImageStyle[feature.getProperties().IMP_NOM]
  })
};


var vectorSource_I_IMP_Point = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_I_IMP_Point)
});

global.Layer_I_IMP_Point = new VectorLayer({
  title: 'Layer_I_IMP_Point',
  name: '01 Impactos',
  type: 'Capa específica',
  source: vectorSource_I_IMP_Point,
  style: styleFunction_I_IMP_Point,
  zIndex:9
});

var styleFunction_I_IMP_Line = function(feature, resolution) {
  return new  Style({
    stroke: Funct_StrokeStyle[feature.getProperties().IMP_NOM]
  })
};

var vectorSource_I_IMP_Line = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_I_IMP_Line)
});

global.Layer_I_IMP_Line = new VectorLayer({
  title: 'Layer_I_IMP_Line',
  name: '01 Impactos',
  type: 'Capa específica',
  source: vectorSource_I_IMP_Line,
  style: styleFunction_I_IMP_Line,
  zIndex:8
});

var styleFunction_I_IMP_Poly = function(feature, resolution) {
  return new  Style({
    stroke: Funct_StrokeStyle[feature.getProperties().IMP_NOM],
    fill: Funct_FillStyle[feature.getProperties().IMP_NOM]
  })
};

var vectorSource_I_IMP_Poly = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_I_IMP_Poly)
});

global.Layer_I_IMP_Poly = new VectorLayer({
  title: 'Layer_I_IMP_Poly',
  name: '01 Impactos',
  type: 'Capa específica',
  source: vectorSource_I_IMP_Poly,
  style: styleFunction_I_IMP_Poly,
  zIndex:3
});

//Agrupamos todas las capas pertenecientes al mismo plano, para activarlas todas a la misma vez
global.Map_IA_12 = new LayerGroup({
  title:    'Map_IA_12',
  name:     'Impactos',
  type:     'Capa específica',
  opacity:   0.75,
	visible:   false,
  layers:[
    Layer_I_IMP_Point,
    Layer_I_IMP_Line,
    Layer_I_IMP_Poly
  ]
});
////////////////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////////
//CAPA DE INVENTARIO FAUNÍSTICO

var styleFunction_I_ESP_FAUNA_INDEX = function(feature, resolution) {
  var ParametrosLabel = Funct_Label["Layer_I_ESP_FAUNA_INDEX"];

  return new  Style({
    stroke: Funct_StrokeStyle[Symbolyze_Layer_I_ESP_FAUNA_INDEX],
    fill: Funct_FillStyle[Symbolyze_Layer_I_ESP_FAUNA_INDEX],
    text:  createTextStyle(feature, resolution, ParametrosLabel[0],FunctLabelText(feature, ParametrosLabel[1]), ParametrosLabel[2], ParametrosLabel[3], ParametrosLabel[4], ParametrosLabel[5], ParametrosLabel[6], ParametrosLabel[7], ParametrosLabel[8], ParametrosLabel[9], ParametrosLabel[10], ParametrosLabel[11], ParametrosLabel[12], ParametrosLabel[13], ParametrosLabel[14], ParametrosLabel[15]),
  })
};


var vectorSource_I_ESP_FAUNA_INDEX = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_ESP_FAUNA_INDEX)
});

global.Layer_I_ESP_FAUNA_INDEX= new VectorLayer({
  title: 'Layer_I_ESP_FAUNA_INDEX',
  name: 'F02 ' + Symbolyze_Layer_I_ESP_FAUNA_INDEX,
  type: 'Capa específica',
  source: vectorSource_I_ESP_FAUNA_INDEX,
  style: styleFunction_I_ESP_FAUNA_INDEX,
  zIndex:9
});

//Agrupamos todas las capas pertenecientes al mismo plano, para activarlas todas a la misma vez
global.Map_IA_13 = new LayerGroup({
  title:    'Map_IA_13',
  name:     'Inventario de especies faunísticas',
  type:     'Capa específica',
  opacity:   0.75,
	visible:   false,
  layers:[
    Layer_I_ESP_FAUNA_INDEX
  ]
});
////////////////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////////
//CAPA DE NIDIFICACIÓN

var styleFunction_Aves_nidificantes = function(feature, resolution) {
  var ParametrosLabel = Funct_Label["Layer_Aves_nidificantes"];

  return new  Style({
    stroke: Funct_StrokeStyle[Symbolyze_Layer_Aves_nidificantes],
    fill: Funct_FillStyle[Symbolyze_Layer_Aves_nidificantes],
    text:  createTextStyle(feature, resolution, ParametrosLabel[0],FunctLabelText(feature, ParametrosLabel[1]), ParametrosLabel[2], ParametrosLabel[3], ParametrosLabel[4], ParametrosLabel[5], ParametrosLabel[6], ParametrosLabel[7], ParametrosLabel[8], ParametrosLabel[9], ParametrosLabel[10], ParametrosLabel[11], ParametrosLabel[12], ParametrosLabel[13], ParametrosLabel[14], ParametrosLabel[15]),
  })
};


var vectorSource_Aves_nidificantes = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_ESP_Nidificantes)
});

global.Layer_Aves_nidificantes = new VectorLayer({
  title:  'Layer_Aves_nidificantes',
  name:   'F01 ' + Symbolyze_Layer_Aves_nidificantes,
  type:   'Capa específica',
  source: vectorSource_Aves_nidificantes,
  style:  styleFunction_Aves_nidificantes,
  zIndex: 3
});


//Agrupamos todas las capas pertenecientes al mismo plano, para activarlas todas a la misma vez
global.Map_IA_14 = new LayerGroup({
  title:    'Map_IA_14',
  name:     'Nidificación',
  type:     'Capa específica',
  opacity:   0.5,
	visible:   false,
  layers:[
    Layer_Aves_nidificantes
  ]
});
////////////////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////////
//CAPA DE PAISAJE

var styleFunction_I_PAISAJE_ATLAS = function(feature, resolution) {
  return new  Style({
    stroke: Funct_StrokeStyle[feature.getProperties().UNIDAD_PAI],
    fill: Funct_FillStyle[feature.getProperties().UNIDAD_PAI]
  })
};


var GeoJSON_I_PAISAJE_ATLAS1_Filter = {
  "type": "FeatureCollection",
  "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::" + DefaultCRS } },
  "features": []
};

for(var i = 0; i<GeoJSON_I_PAISAJE_ATLAS.features.length; i++){
  var feature =GeoJSON_I_PAISAJE_ATLAS.features[i];

  if ((feature.properties["UNIDAD_PAI"] == "Cumbres de Gran Canaria")) {
    GeoJSON_I_PAISAJE_ATLAS1_Filter.features.push(feature);
  }
};

var vectorSource_I_PAISAJE_ATLAS1 = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_I_PAISAJE_ATLAS1_Filter)
});

global.Layer_I_PAISAJE_ATLAS1 = new VectorLayer({
  title: 'Layer_I_PAISAJE_ATLAS1',
  name: '01 Cumbres de canarias',
  type: 'Capa específica',
  source: vectorSource_I_PAISAJE_ATLAS1,
  style: styleFunction_I_PAISAJE_ATLAS,
  zIndex:3
});




var GeoJSON_I_PAISAJE_ATLAS2_Filter = {
  "type": "FeatureCollection",
  "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::" + DefaultCRS } },
  "features": []
};

for(var i = 0; i<GeoJSON_I_PAISAJE_ATLAS.features.length; i++){
  var feature =GeoJSON_I_PAISAJE_ATLAS.features[i];

  if ((feature.properties["UNIDAD_PAI"] !== "Cumbres de Gran Canaria")) {
    GeoJSON_I_PAISAJE_ATLAS2_Filter.features.push(feature);
  }
};

var vectorSource_I_PAISAJE_ATLAS2 = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_I_PAISAJE_ATLAS2_Filter)
});

global.Layer_I_PAISAJE_ATLAS2 = new VectorLayer({
  title: 'Layer_I_PAISAJE_ATLAS2',
  name: '02 Rampas, barrancos y valles canarios',
  type: 'Capa específica',
  source: vectorSource_I_PAISAJE_ATLAS2,
  style: styleFunction_I_PAISAJE_ATLAS,
  zIndex:3
});


//Agrupamos todas las capas pertenecientes al mismo plano, para activarlas todas a la misma vez
global.Map_IA_17 = new LayerGroup({
  title:    'Map_IA_17',
  name:     'Paisaje',
  type:     'Capa específica',
  opacity:   0.75,
	visible:   false,
  layers:[
    Layer_I_PAISAJE_ATLAS1,
    Layer_I_PAISAJE_ATLAS2
  ]
});
////////////////////////////////////////////////////////////////////////

import GeoJSON from 'ol/format/GeoJSON.js';
import {Vector as VectorLayer, Group as LayerGroup} from 'ol/layer.js';
import {Vector as VectorSource} from 'ol/source.js';
import {Fill, Stroke, Style} from 'ol/style.js';



////////////////////////////////////////////////////////////////////////
//CAPA DE FRAGILIDAD VISUAL
var styleFunction_IP_UA_Fragilidad = function(feature, resolution) {
  var ParametrosLabel = Funct_Label["Layer_D_UA"];

  return new  Style({
    fill: FunctionColorRellenoGradual(feature.getProperties().MEAN, Funct_FillStyle[Symbolyze_Layer_IP_UA_Fragilidad]),
    stroke: Funct_StrokeStyle[Symbolyze_Layer_IP_UA_Fragilidad],
    text: createTextStyle(feature, resolution, ParametrosLabel[0],FunctLabelText(feature, ParametrosLabel[1]), ParametrosLabel[2], ParametrosLabel[3], ParametrosLabel[4], ParametrosLabel[5], ParametrosLabel[6], ParametrosLabel[7], ParametrosLabel[8], ParametrosLabel[9], ParametrosLabel[10], ParametrosLabel[11], ParametrosLabel[12], ParametrosLabel[13], ParametrosLabel[14], ParametrosLabel[15])
  })
};


var vectorSource_IP_UA_Fragilidad = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_D_UA_Fragilidad)
});

global.Layer_IP_UA_Fragilidad = new VectorLayer({
  title: 'Layer_IP_UA_Fragilidad',
  name: 'GD ' + Symbolyze_Layer_IP_UA_Fragilidad,
  type: 'Capa específica',
  source: vectorSource_IP_UA_Fragilidad,
  style: styleFunction_IP_UA_Fragilidad,
  zIndex:3
});

//Agrupamos todas las capas pertenecientes al mismo plano, para activarlas todas a la misma vez
global.Map_IP_01b = new LayerGroup({
  title:    'Map_IP_01b',
  name:     'Fragilidad visual. Unidades Homogéneas',
  type:     'Capa específica',
  opacity:   0.75,
	visible:   false,
  layers:[
    Layer_IP_UA_Fragilidad
  ]
});
////////////////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////////
//CAPA DE CALIDAD VISUAL
var styleFunction_IP_UA_Calidad = function(feature, resolution) {
  var ParametrosLabel = Funct_Label["Layer_D_UA"];

  return new  Style({
    fill: FunctionColorRellenoGradual(feature.getProperties().MEAN, Funct_FillStyle[Symbolyze_Layer_IP_UA_Calidad]),
    stroke: Funct_StrokeStyle[Symbolyze_Layer_IP_UA_Calidad],
    text: createTextStyle(feature, resolution, ParametrosLabel[0],FunctLabelText(feature, ParametrosLabel[1]), ParametrosLabel[2], ParametrosLabel[3], ParametrosLabel[4], ParametrosLabel[5], ParametrosLabel[6], ParametrosLabel[7], ParametrosLabel[8], ParametrosLabel[9], ParametrosLabel[10], ParametrosLabel[11], ParametrosLabel[12], ParametrosLabel[13], ParametrosLabel[14], ParametrosLabel[15])
  })
};


var vectorSource_IP_UA_Calidad = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_D_UA_Calidad)
});

global.Layer_IP_UA_Calidad = new VectorLayer({
  title: 'Layer_IP_UA_Calidad',
  name: 'GD ' + Symbolyze_Layer_IP_UA_Calidad,
  type: 'Capa específica',
  source: vectorSource_IP_UA_Calidad,
  style: styleFunction_IP_UA_Calidad,
  zIndex:3
});

//Agrupamos todas las capas pertenecientes al mismo plano, para activarlas todas a la misma vez
global.Map_IP_02b = new LayerGroup({
  title:    'Map_IP_02b',
  name:     'Calidad visual. Unidades Homogéneas',
  type:     'Capa específica',
  opacity:   0.75,
	visible:   false,
  layers:[
    Layer_IP_UA_Calidad
  ]
});
////////////////////////////////////////////////////////////////////////
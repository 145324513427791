import GeoJSON from 'ol/format/GeoJSON.js';
import {Vector as VectorLayer, Group as LayerGroup} from 'ol/layer.js';
import {Vector as VectorSource} from 'ol/source.js';
import {Fill, Stroke, Style} from 'ol/style.js';



////////////////////////////////////////////////////////////////////////
//Función para capa de ENP

var GeoJSON_ENP_Filter = {
  "type": "FeatureCollection",
  "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::" + DefaultCRS } },
  "features": []
};

for(var i = 0; i<GeoJSON_ENP.features.length; i++){
  var feature =GeoJSON_ENP.features[i];

    if (feature.properties["FECHA_APRO"] !== null || feature.properties["SITE_NAME"] == 'Doramas') {
    GeoJSON_ENP_Filter.features.push(feature);
  }
};

var vectorSourceENP_CAT = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_ENP_Filter)
});


global.styleFunction_ENP_CAT = function(feature, resolution) {
  var estilo;

  if(feature.getProperties().FECHA_APRO != null){
    estilo = new  Style({
      stroke: Funct_StrokeStyle[feature.getProperties().NOM_COMPLE],
      fill: Funct_FillStyle[feature.getProperties().NOM_COMPLE],
      text: createTextStyle(feature, resolution, 'none', feature.get("Tipo") + " " + feature.get("SITE_NAME") +  "\nAprobado en: " + feature.get("FECHA_APRO"), "Arial", 0.6, 1, "rgba(0, 0, 0, 1)", 1, 'rgba(255, 255, 255, 1)', 2, 1, 'rgba(255, 255, 255, 1)', "rgba(0, 0, 0, 0.75)", 0.2, [2,50,2,50], 0, 0),
      zIndex: Funct_ZIndexStyle[feature.getProperties().NOM_COMPLE]
    });
  }else if(feature.getProperties().SITE_NAME=='Doramas'){
    estilo = new  Style({
      stroke: Funct_StrokeStyle[feature.getProperties().NOM_COMPLE],
      fill: Funct_FillStyle[feature.getProperties().NOM_COMPLE],
      text: createTextStyle(feature, resolution, 'none', feature.get("Tipo") + " " + feature.get("SITE_NAME"), "Arial", 0.6, 1, "rgba(0, 0, 0, 1)", 1, 'rgba(255, 255, 255, 1)', 2, 1, 'rgba(255, 255, 255, 1)', "rgba(0, 0, 0, 0.75)", 0.2, [2,50,2,50], 0, 0),
      zIndex: Funct_ZIndexStyle[feature.getProperties().NOM_COMPLE]
    });
  }

  return estilo
};

global.Layer_CAT_ENP = new VectorLayer({
  title: 'Layer_CAT_ENP',
  name: '01 Espacio Natural Protegido',
  type: 'Capa específica',
  source: vectorSourceENP_CAT,
  style: styleFunction_ENP_CAT,
  zIndex:8
});
////////////////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////////
//CATÁLOGO
var styleFunction_CAT_ATQUI  = function(feature, resolution) {
  var ParametrosLabel = Funct_Label["Layer_CAT_ATQUI"];

  return new  Style({
    stroke: Funct_StrokeStyle[Symbolyze_Layer_CAT_ATQUI],
    fill: Funct_FillStyle[Symbolyze_Layer_CAT_ATQUI],
    text: createTextStyle(feature, resolution, ParametrosLabel[0],FunctLabelText(feature, ParametrosLabel[1]), ParametrosLabel[2], ParametrosLabel[3], ParametrosLabel[4], ParametrosLabel[5], ParametrosLabel[6], ParametrosLabel[7], ParametrosLabel[8], ParametrosLabel[9], ParametrosLabel[10], ParametrosLabel[11], ParametrosLabel[12], ParametrosLabel[13], ParametrosLabel[14], ParametrosLabel[15])
  })
};


var vectorSource_CAT_ATQUI  = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_CAT_ARQUI)
});

global.Layer_CAT_ATQUI = new VectorLayer({
  title: 'Layer_CAT_ATQUI',
  name: 'F02 ' + Symbolyze_Layer_CAT_ATQUI,
  type: 'Capa específica',
  source: vectorSource_CAT_ATQUI,
  style: styleFunction_CAT_ATQUI,
  zIndex:5
});

var styleFunction_CAT_ETNO  = function(feature, resolution) {
  var ParametrosLabel = Funct_Label["Layer_CAT_ETNO"];

  return new  Style({
    image: Funct_ImageStyle[Symbolyze_Layer_CAT_ETNO],
    text: createTextStyle(feature, resolution, ParametrosLabel[0],FunctLabelText(feature, ParametrosLabel[1]), ParametrosLabel[2], ParametrosLabel[3], ParametrosLabel[4], ParametrosLabel[5], ParametrosLabel[6], ParametrosLabel[7], ParametrosLabel[8], ParametrosLabel[9], ParametrosLabel[10], ParametrosLabel[11], ParametrosLabel[12], ParametrosLabel[13], ParametrosLabel[14], ParametrosLabel[15])
  })
};

var vectorSource_CAT_ETNO  = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_CAT_ETNO)
});

global.Layer_CAT_ETNO = new VectorLayer({
  title: 'Layer_CAT_ETNO',
  name: 'F03 ' + Symbolyze_Layer_CAT_ETNO,
  type: 'Capa específica',
  source: vectorSource_CAT_ETNO,
  style: styleFunction_CAT_ETNO,
  zIndex:9
});

var styleFunction_CAT_INDUST  = function(feature, resolution) {
  var ParametrosLabel = Funct_Label["Layer_CAT_INDUST"];

  return new  Style({
    image: Funct_ImageStyle[Symbolyze_Layer_CAT_INDUST],
    text: createTextStyle(feature, resolution, ParametrosLabel[0],FunctLabelText(feature, ParametrosLabel[1]), ParametrosLabel[2], ParametrosLabel[3], ParametrosLabel[4], ParametrosLabel[5], ParametrosLabel[6], ParametrosLabel[7], ParametrosLabel[8], ParametrosLabel[9], ParametrosLabel[10], ParametrosLabel[11], ParametrosLabel[12], ParametrosLabel[13], ParametrosLabel[14], ParametrosLabel[15])
  })
};

var vectorSource_CAT_INDUST  = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_CAT_INDUST)
});

global.Layer_CAT_INDUST = new VectorLayer({
  title: 'Layer_CAT_INDUST',
  name: 'F04 ' + Symbolyze_Layer_CAT_INDUST,
  type: 'Capa específica',
  source: vectorSource_CAT_INDUST,
  style: styleFunction_CAT_INDUST,
  zIndex:9
});

var styleFunction_CAT_CAMINO_VEC  = function(feature, resolution) {
  var ParametrosLabel = Funct_Label["Layer_CAT_CAMINO_VEC"];

  return new  Style({
    stroke: Funct_StrokeStyle[Symbolyze_Layer_CAT_CAMINO_VEC],
    text: createTextStyle(feature, resolution, ParametrosLabel[0],FunctLabelText(feature, ParametrosLabel[1]), ParametrosLabel[2], ParametrosLabel[3], ParametrosLabel[4], ParametrosLabel[5], ParametrosLabel[6], ParametrosLabel[7], ParametrosLabel[8], ParametrosLabel[9], ParametrosLabel[10], ParametrosLabel[11], ParametrosLabel[12], ParametrosLabel[13], ParametrosLabel[14], ParametrosLabel[15])
  })
};

var vectorSource_CAT_CAMINO_VEC  = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_CAT_Caminos)
});

global.Layer_CAT_CAMINO_VEC = new VectorLayer({
  title: 'Layer_CAT_CAMINO_VEC',
  name: 'F05 ' + Symbolyze_Layer_CAT_CAMINO_VEC,
  type: 'Capa específica',
  source: vectorSource_CAT_CAMINO_VEC,
  style: styleFunction_CAT_CAMINO_VEC,
  zIndex:8
});

global.Map_CAT_01 = new LayerGroup({
  title:         'Map_CAT_01',
  name:       'Elementos catalogados',
  type:         'Capa específica',
  opacity:    1,
	visible:     false,
  layers:[
    Layer_CAT_ENP,
    Layer_CAT_ATQUI,
    Layer_CAT_ETNO,
    Layer_CAT_INDUST,
    Layer_CAT_CAMINO_VEC
  ]
});
////////////////////////////////////////////////////////////////////////

import GeoJSON from 'ol/format/GeoJSON.js';
import {Vector as VectorLayer, Group as LayerGroup} from 'ol/layer.js';
import {Vector as VectorSource} from 'ol/source.js';
import {Fill, Stroke, Style} from 'ol/style.js';



////////////////////////////////////////////////////////////////////////
//Función para capa de ENP

var GeoJSON_ENP_Filter = {
  "type": "FeatureCollection",
  "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::" + DefaultCRS } },
  "features": []
};

for(var i = 0; i<GeoJSON_ENP.features.length; i++){
  var feature =GeoJSON_ENP.features[i];

    if (feature.properties["FECHA_APRO"] !== null || feature.properties["SITE_NAME"] == 'Doramas') {
    GeoJSON_ENP_Filter.features.push(feature);
  }
};

var vectorSource_ENP_OP = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_ENP_Filter)
});


global.styleFunction_ENP_OP = function(feature, resolution) {
  var estilo;

  if(feature.getProperties().FECHA_APRO != null){
    estilo = new  Style({
      stroke: Funct_StrokeStyle[feature.getProperties().NOM_COMPLE],
      fill: Funct_FillStyle[feature.getProperties().NOM_COMPLE],
      text: createTextStyle(feature, resolution, 'none', feature.get("Tipo") + " " + feature.get("SITE_NAME") +  "\nAprobado en: " + feature.get("FECHA_APRO"), "Arial", 0.6, 1, "rgba(0, 0, 0, 1)", 1, 'rgba(255, 255, 255, 1)', 2, 1, 'rgba(255, 255, 255, 1)', "rgba(0, 0, 0, 0.75)", 0.2, [2,50,2,50], 0, 0),
      zIndex: Funct_ZIndexStyle[feature.getProperties().NOM_COMPLE]
    });
  }else if(feature.getProperties().SITE_NAME=='Doramas'){
    estilo = new  Style({
      stroke: Funct_StrokeStyle[feature.getProperties().NOM_COMPLE],
      fill: Funct_FillStyle[feature.getProperties().NOM_COMPLE],
      text: createTextStyle(feature, resolution, 'none', feature.get("Tipo") + " " + feature.get("SITE_NAME"), "Arial", 0.6, 1, "rgba(0, 0, 0, 1)", 1, 'rgba(255, 255, 255, 1)', 2, 1, 'rgba(255, 255, 255, 1)', "rgba(0, 0, 0, 0.75)", 0.2, [2,50,2,50], 0, 0),
      zIndex: Funct_ZIndexStyle[feature.getProperties().NOM_COMPLE]
    });
  }

  return estilo
};

global.Layer_OP_ENP = new VectorLayer({
  title: 'Layer_OP_ENP',
  name: '01 Espacio Natural Protegido',
  type: 'Capa específica',
  source: vectorSource_ENP_OP,
  style: styleFunction_ENP_OP,
  zIndex:8
});
////////////////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////////
//CAPA DE ORDENACIÓN PORMENORIZADA

var GeoJSON_OE_SG_Filter = {
  "type": "FeatureCollection",
  "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::" + DefaultCRS } },
  "features": []
};

for(var i = 0; i<GeoJSON_OE_SG.features.length; i++){
  var feature =GeoJSON_OE_SG.features[i];

  if (feature.properties["TIPO_DES"] !== 'Sistema General de Viario') {
    GeoJSON_OE_SG_Filter.features.push(feature);
  }
};

var vectorSource_OP_SG  = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_OE_SG_Filter)
});

var styleFunction_OP_SG  = function(feature, resolution) {
  return new  Style({
    stroke: Funct_StrokeStyle[feature.getProperties().TIPO + ' OP'],
    fill: Funct_FillStyle[feature.getProperties().TIPO + ' OP']
  })
};

global.Layer_OP_SG = new VectorLayer({
  title: 'Layer_OP_SG',
  name: '02 Delimitaciones',
  type: 'Capa específica',
  source: vectorSource_OP_SG,
  style: styleFunction_OP_SG,
  zIndex:8
});

var GeoJSON_OE_SG_Filter2 = {
  "type": "FeatureCollection",
  "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::" + DefaultCRS } },
  "features": []
};

for(var i = 0; i<GeoJSON_OE_SG.features.length; i++){
  var feature =GeoJSON_OE_SG.features[i];

  if (feature.properties["TIPO_DES"] == 'Sistema General de Viario') {
    GeoJSON_OE_SG_Filter2.features.push(feature);
  }
};

var vectorSource_OP_SG2  = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_OE_SG_Filter2)
});

var styleFunction_OP_SG  = function(feature, resolution) {
  return new  Style({
    stroke: Funct_StrokeStyle[feature.getProperties().TIPO_DES + " OP"],
    fill: Funct_FillStyle[feature.getProperties().TIPO_DES + " OP"]
  })
};

global.Layer_OP_SG2 = new VectorLayer({
  title: 'Layer_OP_SG2',
  name: '02 Delimitaciones',
  type: 'Capa específica',
  source: vectorSource_OP_SG2,
  style: styleFunction_OP_SG,
  zIndex:8
});

var vectorSource_OP_DESARROLLO  = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_OP_DESARROLLO)
});

var styleFunction_OP_DESARROLLO  = function(feature, resolution) {
  var ParametrosLabel = Funct_Label["Layer_OP_DESARROLLO"];

  return new  Style({
    stroke: Funct_StrokeStyle[Symbolyze_Layer_DESARROLLO],
    fill: Funct_FillStyle[Symbolyze_Layer_DESARROLLO],
    text: createTextStyle(feature, resolution, ParametrosLabel[0],FunctLabelText(feature, ParametrosLabel[1]), ParametrosLabel[2], ParametrosLabel[3], ParametrosLabel[4], ParametrosLabel[5], ParametrosLabel[6], ParametrosLabel[7], ParametrosLabel[8], ParametrosLabel[9], ParametrosLabel[10], ParametrosLabel[11], ParametrosLabel[12], ParametrosLabel[13], ParametrosLabel[14], ParametrosLabel[15])
  })
};

global.Layer_OP_DESARROLLO = new VectorLayer({
  title: 'Layer_OP_DESARROLLO',
  name: 'F03 ' + Symbolyze_Layer_DESARROLLO,
  type: 'Capa específica',
  source: vectorSource_OP_DESARROLLO,
  style: styleFunction_OP_DESARROLLO,
  zIndex:9
});

var styleFunction_OP_LIM_EDIF  = function(feature, resolution) {
  return new  Style({
    stroke: Funct_StrokeStyle[Symbolyze_Layer_LIM_EDIF]
  })
};

var vectorSource_OP_LIM_EDIF  = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_O_LIM_EDIF)
});

global.Layer_OP_LIM_EDIF = new VectorLayer({
  title: 'Layer_OP_LIM_EDIF',
  name: 'F04 ' + Symbolyze_Layer_LIM_EDIF,
  type: 'Capa específica',
  source: vectorSource_OP_LIM_EDIF,
  style: styleFunction_OP_LIM_EDIF,
  zIndex:8
});

var styleFunction_OP_ALINEACION  = function(feature, resolution) {
  return new  Style({
    stroke: Funct_StrokeStyle[Symbolyze_Layer_ALINEACION]
  })
};

var vectorSource_OP_ALINEACION  = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_O_ALINEACION)
});

global.Layer_OP_ALINEACION = new VectorLayer({
  title: 'Layer_OP_ALINEACION',
  name: 'F05 ' + Symbolyze_Layer_ALINEACION,
  type: 'Capa específica',
  source: vectorSource_OP_ALINEACION,
  style: styleFunction_OP_ALINEACION,
  zIndex:7
});

var styleFunction_OP_RASANTES  = function(feature, resolution) {
  var ParametrosLabel = Funct_Label["Layer_RASANTES"];

  return new  Style({
    image: Funct_ImageStyle[Symbolyze_Layer_RASANTES],
    text: createTextStyle(feature, resolution, ParametrosLabel[0],FunctLabelText(feature, ParametrosLabel[1]), ParametrosLabel[2], ParametrosLabel[3], ParametrosLabel[4], ParametrosLabel[5], ParametrosLabel[6], ParametrosLabel[7], ParametrosLabel[8], ParametrosLabel[9], ParametrosLabel[10], ParametrosLabel[11], ParametrosLabel[12], ParametrosLabel[13], ParametrosLabel[14], ParametrosLabel[15])
  })
};

var vectorSource_OP_RASANTES  = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_O_RASANTES)
});

global.Layer_OP_RASANTES = new VectorLayer({
  title: 'Layer_OP_RASANTES',
  name: 'F06 ' + Symbolyze_Layer_RASANTES,
  type: 'Capa específica',
  source: vectorSource_OP_RASANTES,
  style: styleFunction_OP_RASANTES,
  zIndex:9
});




/*
var GeoJSON_O_MODORD_line_filter = {
  "type": "FeatureCollection",
  "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::" + DefaultCRS } },
  "features": []
};

for(var i = 0; i<GeoJSON_O_MODORD_line.features.length; i++){
  var feature =GeoJSON_O_MODORD_line.features[i];

  if (feature.properties["CATEG"] =='Suelo Urbano Consolidado' || feature.properties["CATEG"] =='Suelo Urbano No Consolidado' || feature.properties["CATEG"] =='Suelo Rústico de Asentamiento Rural' || feature.properties["CATEG"] =='Suelo Urbanizable Ordenado' ) {
    GeoJSON_OGeoJSON_O_MODORD_lineP_MODORD.features.push(feature);
  }
};

var vectorSource_OP_MODORD_CONTORNO = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_O_MODORD_line_filter)
});

var styleFunction_OP_MODORD_CONTORNO = function(feature, resolution) {
  return new  Style({
    stroke: Funct_StrokeStyle[feature.getProperties().CATEG + ' CONTORNO'],
    zIndex: Funct_ZIndexStyle[feature.getProperties().CATEG + ' CONTORNO']
  })
};

global.Layer_OP_MODORD_CONTORNO = new VectorLayer({
  title: 'Layer_OP_MODORD_CONTORNO',
  name:  '07 División de suelo',
  type: 'Capa específica',
  source: vectorSource_OP_MODORD_CONTORNO,
  style: styleFunction_OP_MODORD_CONTORNO,
  zIndex:8
});
*/

var GeoJSON_O_MODORD_filter = {
  "type": "FeatureCollection",
  "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::" + DefaultCRS } },
  "features": []
};

for(var i = 0; i<GeoJSON_O_MODORD.features.length; i++){
  var feature =GeoJSON_O_MODORD.features[i];

  if (feature.properties["CATEG"] =='Suelo Urbano Consolidado' || feature.properties["CATEG"] =='Suelo Urbano No Consolidado' || feature.properties["CATEG"] =='Suelo Rústico de Asentamiento Rural' || feature.properties["CATEG"] =='Suelo Urbanizable Ordenado' ) {
    GeoJSON_O_MODORD_filter.features.push(feature);
  }
};

var vectorSource_OP_MODORD = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_O_MODORD_filter)
});

var styleFunction_OP_MODORD = function(feature, resolution) {
  var ParametrosLabel = Funct_Label["Layer_OP_MODORD"];

  return new  Style({
    fill: Funct_FillStyle[feature.getProperties().CATEG + ' CONTORNO'],
    stroke: Funct_StrokeStyle[feature.getProperties().CATEG + ' CONTORNO'],
    text: createTextStyle(feature, resolution, ParametrosLabel[0],FunctLabelText(feature, ParametrosLabel[1]), ParametrosLabel[2], ParametrosLabel[3], ParametrosLabel[4], ParametrosLabel[5], ParametrosLabel[6], ParametrosLabel[7], ParametrosLabel[8], ParametrosLabel[9], ParametrosLabel[10], ParametrosLabel[11], ParametrosLabel[12], ParametrosLabel[13], ParametrosLabel[14], ParametrosLabel[15]),
    zIndex: Funct_ZIndexStyle[feature.getProperties().CATEG + ' CONTORNO']
  })
};

global.Layer_OP_MODORD = new VectorLayer({
  title: 'Layer_OP_MODORD',
  name:  '07 División de suelo',
  type: 'Capa específica',
  source: vectorSource_OP_MODORD,
  style: styleFunction_OP_MODORD,
  zIndex:8
});

var GeoJSON_OP_Viario_Eje_Filter = {
  "type": "FeatureCollection",
  "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::" + DefaultCRS } },
  "features": []
};

GeoJSON_OP_Viario_Eje_FilterNulls  = FiltrarNulosGeoJson(GeoJSON_O_Red_Viaria_Eje, "JERARQUIA")

for(var i = 0; i<GeoJSON_OP_Viario_Eje_FilterNulls.features.length; i++){
  var feature =GeoJSON_OP_Viario_Eje_FilterNulls.features[i];

  if (feature.properties["JERARQUIA"] !== 'Sendero') {
    GeoJSON_OP_Viario_Eje_Filter.features.push(feature);
  }
};

var vectorSource_OP_Viario_Eje  = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_OP_Viario_Eje_Filter)
});

var styleFunction_OP_Viario_Eje  = function(feature, resolution) {
  return new  Style({
    stroke: Funct_StrokeStyle[feature.getProperties().JERARQUIA]
  })
};

global.Layer_OP_Viario_Eje = new VectorLayer({
  title: 'Layer_OP_Viario_Eje',
  name: '08 Transporte y comunicación',
  type: 'Capa específica',
  source: vectorSource_OP_Viario_Eje,
  style: styleFunction_OP_Viario_Eje,
  zIndex:8
});






GeoJSON_OP_Viario_Eje_Ancho_FilterNulls  = FiltrarNulosGeoJson(GeoJSON_O_Red_Viaria_Eje, "ANCHO_VIA")



var GeoJSON_OP_Viario_Eje_ANCHO_Filter = {
  "type": "FeatureCollection",
  "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::" + DefaultCRS } },
  "features": []
};

for(var i = 0; i<GeoJSON_OP_Viario_Eje_Ancho_FilterNulls.features.length; i++){
  var feature =GeoJSON_OP_Viario_Eje_Ancho_FilterNulls.features[i];

  if (feature.properties["ANCHO_VIA"] > 0) {
    GeoJSON_OP_Viario_Eje_ANCHO_Filter.features.push(feature);
  }
};

var vectorSource_OP_Viario_Eje_ANCHO  = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_OP_Viario_Eje_ANCHO_Filter)
});

var styleFunction_OP_Viario_Eje_ANCHO  = function(feature, resolution) {
  var ParametrosLabel = Funct_Label["Layer_OP_Viario_Eje_ANCHO"];

  return new  Style({
    text: createTextStyle(feature, resolution, ParametrosLabel[0],FunctLabelText(feature, ParametrosLabel[1]), ParametrosLabel[2], ParametrosLabel[3], ParametrosLabel[4], ParametrosLabel[5], ParametrosLabel[6], ParametrosLabel[7], ParametrosLabel[8], ParametrosLabel[9], ParametrosLabel[10], ParametrosLabel[11], ParametrosLabel[12], ParametrosLabel[13], ParametrosLabel[14], ParametrosLabel[15])
  })
};

global.Layer_OP_Viario_Eje_ANCHO = new VectorLayer({
  title: 'Layer_OP_Viario_Eje_ANCHO',
  name: 'NL Ancho vía',
  type: 'Capa específica',
  source: vectorSource_OP_Viario_Eje_ANCHO,
  style: styleFunction_OP_Viario_Eje_ANCHO,
  zIndex:9
});





var GeoJSON_OP_ZONIFICACION_INFRA_Filter = {
  "type": "FeatureCollection",
  "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::" + DefaultCRS } },
  "features": []
};

for(var i = 0; i<GeoJSON_O_ZON.features.length; i++){
  var feature =GeoJSON_O_ZON.features[i];

  if (feature.properties["ZONA"] == 'Aparcamiento privado' || feature.properties["ZONA"] == 'Aparcamiento' || feature.properties["ZONA"] == 'Estación de servicio' || feature.properties["ZONA"] == 'Acera') {
    GeoJSON_OP_ZONIFICACION_INFRA_Filter.features.push(feature);
  }
};

var vectorSource_OP_ZONIFICACION_INFRA  = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_OP_ZONIFICACION_INFRA_Filter)
});

var styleFunction_OP_ZONIFICACION_INFRA  = function(feature, resolution) {
  var ValorSimbolizacion = Leyenda_OP_ZONIFICACION[feature.getProperties().ZONA][1];

  return new  Style({
    stroke: Funct_StrokeStyle[ValorSimbolizacion +" OP"],
    fill: Funct_FillStyle[ValorSimbolizacion + " OP"]
  })
};

global.Layer_OP_ZONIFICACION_INFRA = new VectorLayer({
  title: 'Layer_OP_ZONIFICACION_INFRA',
  name: '08 Transporte y comunicación',
  type: 'Capa específica',
  source: vectorSource_OP_ZONIFICACION_INFRA,
  style: styleFunction_OP_ZONIFICACION_INFRA,
  zIndex:6
});

var GeoJSON_OP_VIARIO_Filter = {
  "type": "FeatureCollection",
  "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::" + DefaultCRS } },
  "features": []
};

for(var i = 0; i<GeoJSON_O_Red_Viaria.features.length; i++){
  var feature =GeoJSON_O_Red_Viaria.features[i];

  if (feature.properties["TIPO"] == 'Peatonal') {
    GeoJSON_OP_VIARIO_Filter.features.push(feature);
  }
};

var vectorSource_OP_VIARIO  = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_OP_VIARIO_Filter)
});

var styleFunction_OP_VIARIO  = function(feature, resolution) {
  return new  Style({
    stroke: Funct_StrokeStyle[Symbolyze_Layer_OP_VIARIO],
    fill: Funct_FillStyle[Symbolyze_Layer_OP_VIARIO]
  })
};

global.Layer_OP_VIARIO = new VectorLayer({
  title: 'Layer_OP_VIARIO',
  name: 'F09 ' + Symbolyze_Layer_OP_VIARIO,
  type: 'Capa específica',
  source: vectorSource_OP_VIARIO,
  style: styleFunction_OP_VIARIO,
  zIndex:8
});



var GeoJSON_OP_VIARIO_fondo_Filter = {
  "type": "FeatureCollection",
  "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::" + DefaultCRS } },
  "features": []
};

for(var i = 0; i<GeoJSON_O_Red_Viaria.features.length; i++){
  var feature =GeoJSON_O_Red_Viaria.features[i];

  if (feature.properties["TIPO"] == '3' || feature.properties["TIPO"] == '4,5' || feature.properties["TIPO"] == '5' || feature.properties["TIPO"] == '6' || feature.properties["TIPO"] == '7' || feature.properties["TIPO"] == '9' || feature.properties["TIPO"] == 'Sendero' || feature.properties["TIPO"] == 'Camino' || feature.properties["TIPO"] == 'Variable' || feature.properties["TIPO"] == 'GC-214' || feature.properties["TIPO"] == 'GC-230' || feature.properties["TIPO"] == 'GC-305' || feature.properties["TIPO"] == 'GC-307' || feature.properties["TIPO"] == 'gc-30' || feature.properties["TIPO"] == 'GC-150' || feature.properties["TIPO"] == 'GC21') {
    GeoJSON_OP_VIARIO_fondo_Filter.features.push(feature);
  }
};

var vectorSource_OP_VIARIO_fondo  = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_OP_VIARIO_fondo_Filter)
});

var styleFunction_OP_VIARIO_fondo  = function(feature, resolution) {
  return new  Style({
    stroke:  new  Stroke({
      color:  'rgba(0, 0, 0, 1)',
      width: 0.5
    }),
  })
};

global.Layer_OP_VIARIO_fondo = new VectorLayer({
  title: 'Layer_OP_VIARIO_fondo',
  name: 'NL Contorno viario',
  type: 'Capa específica',
  source: vectorSource_OP_VIARIO_fondo,
  style: styleFunction_OP_VIARIO_fondo,
  zIndex:8
});

var GeoJSON_OP_ZONIFICACION_RES_Filter = {
  "type": "FeatureCollection",
  "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::" + DefaultCRS } },
  "features": []
};

for(var i = 0; i<GeoJSON_O_ZON.features.length; i++){
  var feature =GeoJSON_O_ZON.features[i];

  if (feature.properties["ZONA"] == 'Residencial' || feature.properties["ZONA"] == 'Residencial / Terciario' || feature.properties["ZONA"] == 'Suelo vacante' || feature.properties["ZONA"] == 'Residencial (VPP)') {
    GeoJSON_OP_ZONIFICACION_RES_Filter.features.push(feature);
  }
};

var vectorSource_OP_ZONIFICACION_RES  = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_OP_ZONIFICACION_RES_Filter)
});

var styleFunction_OP_ZONIFICACION_RES  = function(feature, resolution) {
  var ValorSimbolizacion = Leyenda_OP_ZONIFICACION[feature.getProperties().ZONA][1];
  var ParametrosLabel = Funct_Label["Layer_OP_ZONIFICACION_RES"];

  return new  Style({
    stroke: Funct_StrokeStyle[ValorSimbolizacion + " OP"],
    fill: Funct_FillStyle[ValorSimbolizacion + " OP"],
    text: createTextStyle(feature, resolution, ParametrosLabel[0],FunctLabelText(feature, ParametrosLabel[1]), ParametrosLabel[2], ParametrosLabel[3], ParametrosLabel[4], ParametrosLabel[5], ParametrosLabel[6], ParametrosLabel[7], ParametrosLabel[8], ParametrosLabel[9], ParametrosLabel[10], ParametrosLabel[11], ParametrosLabel[12], ParametrosLabel[13], ParametrosLabel[14], ParametrosLabel[15])
  })
};

global.Layer_OP_ZONIFICACION_RES = new VectorLayer({
  title: 'Layer_OP_ZONIFICACION_RES',
  name: '10 Residencial',
  type: 'Capa específica',
  source: vectorSource_OP_ZONIFICACION_RES,
  style: styleFunction_OP_ZONIFICACION_RES,
  zIndex:6
});

var GeoJSON_OP_ZONIFICACION_EL_Filter = {
  "type": "FeatureCollection",
  "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::" + DefaultCRS } },
  "features": []
};

for(var i = 0; i<GeoJSON_O_ZON.features.length; i++){
  var feature =GeoJSON_O_ZON.features[i];

  if (feature.properties["ZONA"] == 'Zona ajardinada' || feature.properties["ZONA"] == 'Plaza' || feature.properties["ZONA"] == 'Parque Urbano') {
    GeoJSON_OP_ZONIFICACION_EL_Filter.features.push(feature);
  }
};

var vectorSource_OP_ZONIFICACION_EL  = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_OP_ZONIFICACION_EL_Filter)
});

var styleFunction_OP_ZONIFICACION_EL  = function(feature, resolution) {
  var ValorSimbolizacion = Leyenda_OP_ZONIFICACION[feature.getProperties().ZONA][1];
  var ParametrosLabel = Funct_Label["Layer_OP_ZONIFICACION_EL"];

  return new  Style({
    stroke: Funct_StrokeStyle[ValorSimbolizacion + " OP"],
    fill: Funct_FillStyle[ValorSimbolizacion + " OP"],
    text: createTextStyle(feature, resolution, ParametrosLabel[0],FunctLabelText(feature, ParametrosLabel[1]), ParametrosLabel[2], ParametrosLabel[3], ParametrosLabel[4], ParametrosLabel[5], ParametrosLabel[6], ParametrosLabel[7], ParametrosLabel[8], ParametrosLabel[9], ParametrosLabel[10], ParametrosLabel[11], ParametrosLabel[12], ParametrosLabel[13], ParametrosLabel[14], ParametrosLabel[15])
  })
};

global.Layer_OP_ZONIFICACION_EL = new VectorLayer({
  title: 'Layer_OP_ZONIFICACION_EL',
  name: '11 Espacios libres',
  type: 'Capa específica',
  source: vectorSource_OP_ZONIFICACION_EL,
  style: styleFunction_OP_ZONIFICACION_EL,
  zIndex:6
});

var GeoJSON_OP_ZONIFICACION_COM_Filter = {
  "type": "FeatureCollection",
  "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::" + DefaultCRS } },
  "features": []
};

for(var i = 0; i<GeoJSON_O_ZON.features.length; i++){
  var feature =GeoJSON_O_ZON.features[i];

  if (feature.properties["ZONA"] == 'Terciario' || feature.properties["ZONA"] == 'Comercial') {
    GeoJSON_OP_ZONIFICACION_COM_Filter.features.push(feature);
  }
};

var vectorSource_OP_ZONIFICACION_COM  = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_OP_ZONIFICACION_COM_Filter)
});

var styleFunction_OP_ZONIFICACION_COM  = function(feature, resolution) {
  var ValorSimbolizacion = Leyenda_OP_ZONIFICACION[feature.getProperties().ZONA][1];
  var ParametrosLabel = Funct_Label["Layer_OP_ZONIFICACION_COM"];

  return new  Style({
    stroke: Funct_StrokeStyle[ValorSimbolizacion + " OP"],
    fill: Funct_FillStyle[ValorSimbolizacion + " OP"],
    text: createTextStyle(feature, resolution, ParametrosLabel[0],FunctLabelText(feature, ParametrosLabel[1]), ParametrosLabel[2], ParametrosLabel[3], ParametrosLabel[4], ParametrosLabel[5], ParametrosLabel[6], ParametrosLabel[7], ParametrosLabel[8], ParametrosLabel[9], ParametrosLabel[10], ParametrosLabel[11], ParametrosLabel[12], ParametrosLabel[13], ParametrosLabel[14], ParametrosLabel[15])
  })
};

global.Layer_OP_ZONIFICACION_COM = new VectorLayer({
  title: 'Layer_OP_ZONIFICACION_COM',
  name: '12 Terciario',
  type: 'Capa específica',
  source: vectorSource_OP_ZONIFICACION_COM,
  style: styleFunction_OP_ZONIFICACION_COM,
  zIndex:6
});

var GeoJSON_OP_ZONIFICACION_IND_Filter = {
  "type": "FeatureCollection",
  "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::" + DefaultCRS } },
  "features": []
};

for(var i = 0; i<GeoJSON_O_ZON.features.length; i++){
  var feature =GeoJSON_O_ZON.features[i];

  if (feature.properties["ZONA"] == 'Industrial') {
    GeoJSON_OP_ZONIFICACION_IND_Filter.features.push(feature);
  }
};

var vectorSource_OP_ZONIFICACION_IND  = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_OP_ZONIFICACION_IND_Filter)
});

var styleFunction_OP_ZONIFICACION_IND  = function(feature, resolution) {
  var ValorSimbolizacion = Leyenda_OP_ZONIFICACION[feature.getProperties().ZONA][1];
  var ParametrosLabel = Funct_Label["Layer_OP_ZONIFICACION_IND"];

  return new  Style({
    stroke: Funct_StrokeStyle[ValorSimbolizacion + " OP"],
    fill: Funct_FillStyle[ValorSimbolizacion + " OP"],
    text: createTextStyle(feature, resolution, ParametrosLabel[0],FunctLabelText(feature, ParametrosLabel[1]), ParametrosLabel[2], ParametrosLabel[3], ParametrosLabel[4], ParametrosLabel[5], ParametrosLabel[6], ParametrosLabel[7], ParametrosLabel[8], ParametrosLabel[9], ParametrosLabel[10], ParametrosLabel[11], ParametrosLabel[12], ParametrosLabel[13], ParametrosLabel[14], ParametrosLabel[15])
  })
};

global.Layer_OP_ZONIFICACION_IND = new VectorLayer({
  title: 'Layer_OP_ZONIFICACION_IND',
  name: '13 Industrial',
  type: 'Capa específica',
  source: vectorSource_OP_ZONIFICACION_IND,
  style: styleFunction_OP_ZONIFICACION_IND,
  zIndex:6
});

var GeoJSON_OP_ZONIFICACION_EQU_Filter = {
  "type": "FeatureCollection",
  "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::" + DefaultCRS } },
  "features": []
};

for(var i = 0; i<GeoJSON_O_ZON.features.length; i++){
  var feature =GeoJSON_O_ZON.features[i];

  if (feature.properties["ZONA"] == 'Equipamiento') {
    GeoJSON_OP_ZONIFICACION_EQU_Filter.features.push(feature);
  }
};

var vectorSource_OP_ZONIFICACION_EQU  = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_OP_ZONIFICACION_EQU_Filter)
});

var styleFunction_OP_ZONIFICACION_EQU  = function(feature, resolution) {
  var ValorSimbolizacion = Leyenda_OP_ZONIFICACION[feature.getProperties().ZONA][1];
  var ParametrosLabel = Funct_Label["Layer_OP_ZONIFICACION_EQU"];

  return new  Style({
    stroke: Funct_StrokeStyle[ValorSimbolizacion + " OP"],
    fill: Funct_FillStyle[ValorSimbolizacion + " OP"],
    text: createTextStyle(feature, resolution, ParametrosLabel[0],FunctLabelText(feature, ParametrosLabel[1]), ParametrosLabel[2], ParametrosLabel[3], ParametrosLabel[4], ParametrosLabel[5], ParametrosLabel[6], ParametrosLabel[7], ParametrosLabel[8], ParametrosLabel[9], ParametrosLabel[10], ParametrosLabel[11], ParametrosLabel[12], ParametrosLabel[13], ParametrosLabel[14], ParametrosLabel[15])
  })
};

global.Layer_OP_ZONIFICACION_EQU = new VectorLayer({
  title: 'Layer_OP_ZONIFICACION_EQU',
  name: '14 Equipamiento',
  type: 'Capa específica',
  source: vectorSource_OP_ZONIFICACION_EQU,
  style: styleFunction_OP_ZONIFICACION_EQU,
  zIndex:6
});

var GeoJSON_OP_ZONIFICACION_TIPOEDIF_Filter = {
  "type": "FeatureCollection",
  "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::" + DefaultCRS } },
  "features": []
};

for(var i = 0; i<GeoJSON_O_ZON.features.length; i++){
  var feature =GeoJSON_O_ZON.features[i];

  if (feature.properties["ZONA"] == 'Residencial' || feature.properties["ZONA"] == 'Residencial / Terciario' || feature.properties["ZONA"] == 'Residencial (VPP)' || feature.properties["ZONA"] == 'Industrial') {
    GeoJSON_OP_ZONIFICACION_TIPOEDIF_Filter.features.push(feature);
  }
};

var vectorSource_OP_ZONIFICACION_TIPOEDIF  = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_OP_ZONIFICACION_TIPOEDIF_Filter)
});

var styleFunction_OP_ZONIFICACION_TIPOEDIF  = function(feature, resolution) {
  var ParametrosLabel = Funct_Label["Layer_OP_ZONIFICACION_TIPOEDIF"];

  return new  Style({
    text: createTextStyle(feature, resolution, ParametrosLabel[0],FunctLabelText(feature, ParametrosLabel[1]), ParametrosLabel[2], ParametrosLabel[3], ParametrosLabel[4], ParametrosLabel[5], ParametrosLabel[6], ParametrosLabel[7], ParametrosLabel[8], ParametrosLabel[9], ParametrosLabel[10], ParametrosLabel[11], ParametrosLabel[12], ParametrosLabel[13], ParametrosLabel[14], ParametrosLabel[15])
  })
};

global.Layer_OP_ZONIFICACION_TIPOEDIF = new VectorLayer({
  title: 'Layer_OP_ZONIFICACION_TIPOEDIF',
  name: 'NL Tipología edificatoria',
  type: 'Capa específica',
  source: vectorSource_OP_ZONIFICACION_TIPOEDIF,
  style: styleFunction_OP_ZONIFICACION_TIPOEDIF,
  zIndex:6
});

var styleFunction_OP_CAT_ATQUI  = function(feature, resolution) {
  var ParametrosLabel = Funct_Label["Layer_CAT_ATQUI"];

  return new  Style({
    stroke: Funct_StrokeStyle[Symbolyze_Layer_CAT_ATQUI],
    fill: Funct_FillStyle[Symbolyze_Layer_CAT_ATQUI],
    text: createTextStyle(feature, resolution, ParametrosLabel[0],FunctLabelText(feature, ParametrosLabel[1]), ParametrosLabel[2], ParametrosLabel[3], ParametrosLabel[4], ParametrosLabel[5], ParametrosLabel[6], ParametrosLabel[7], ParametrosLabel[8], ParametrosLabel[9], ParametrosLabel[10], ParametrosLabel[11], ParametrosLabel[12], ParametrosLabel[13], ParametrosLabel[14], ParametrosLabel[15])
  })
};


var vectorSource_OP_CAT_ATQUI  = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_CAT_ARQUI)
});

global.Layer_OP_CAT_ATQUI = new VectorLayer({
  title: 'Layer_OP_CAT_ATQUI',
  name: 'F51 ' + Symbolyze_Layer_CAT_ATQUI,
  type: 'Capa específica',
  source: vectorSource_OP_CAT_ATQUI,
  style: styleFunction_OP_CAT_ATQUI,
  zIndex:7
});

var styleFunction_OP_CAT_ETNO  = function(feature, resolution) {
  var ParametrosLabel = Funct_Label["Layer_CAT_ETNO"];

  return new  Style({
    image: Funct_ImageStyle[Symbolyze_Layer_CAT_ETNO],
    text: createTextStyle(feature, resolution, ParametrosLabel[0],FunctLabelText(feature, ParametrosLabel[1]), ParametrosLabel[2], ParametrosLabel[3], ParametrosLabel[4], ParametrosLabel[5], ParametrosLabel[6], ParametrosLabel[7], ParametrosLabel[8], ParametrosLabel[9], ParametrosLabel[10], ParametrosLabel[11], ParametrosLabel[12], ParametrosLabel[13], ParametrosLabel[14], ParametrosLabel[15])
  })
};

var vectorSource_OP_CAT_ETNO  = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_CAT_ETNO)
});

global.Layer_OP_CAT_ETNO = new VectorLayer({
  title: 'Layer_OP_CAT_ETNO',
  name: 'F52 ' + Symbolyze_Layer_CAT_ETNO,
  type: 'Capa específica',
  source: vectorSource_OP_CAT_ETNO,
  style: styleFunction_OP_CAT_ETNO,
  zIndex:9
});

var styleFunction_OP_CAT_INDUST  = function(feature, resolution) {
  var ParametrosLabel = Funct_Label["Layer_CAT_INDUST"];

  return new  Style({
    image: Funct_ImageStyle[Symbolyze_Layer_CAT_INDUST],
    text: createTextStyle(feature, resolution, ParametrosLabel[0],FunctLabelText(feature, ParametrosLabel[1]), ParametrosLabel[2], ParametrosLabel[3], ParametrosLabel[4], ParametrosLabel[5], ParametrosLabel[6], ParametrosLabel[7], ParametrosLabel[8], ParametrosLabel[9], ParametrosLabel[10], ParametrosLabel[11], ParametrosLabel[12], ParametrosLabel[13], ParametrosLabel[14], ParametrosLabel[15])
  })
};

var vectorSource_OP_CAT_INDUST  = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_CAT_INDUST)
});

global.Layer_OP_CAT_INDUST = new VectorLayer({
  title: 'Layer_OP_CAT_INDUST',
  name: 'F53 ' + Symbolyze_Layer_CAT_INDUST,
  type: 'Capa específica',
  source: vectorSource_OP_CAT_INDUST,
  style: styleFunction_OP_CAT_INDUST,
  zIndex:9
});

var styleFunction_OP_CAT_CAMINO_VEC  = function(feature, resolution) {
  var ParametrosLabel = Funct_Label["Layer_CAT_CAMINO_VEC"];

  return new  Style({
    stroke: Funct_StrokeStyle[Symbolyze_Layer_CAT_CAMINO_VEC],
    text: createTextStyle(feature, resolution, ParametrosLabel[0],FunctLabelText(feature, ParametrosLabel[1]), ParametrosLabel[2], ParametrosLabel[3], ParametrosLabel[4], ParametrosLabel[5], ParametrosLabel[6], ParametrosLabel[7], ParametrosLabel[8], ParametrosLabel[9], ParametrosLabel[10], ParametrosLabel[11], ParametrosLabel[12], ParametrosLabel[13], ParametrosLabel[14], ParametrosLabel[15])
  })
};

var vectorSource_OP_CAT_CAMINO_VEC  = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_CAT_Caminos)
});

global.Layer_OP_CAT_CAMINO_VEC = new VectorLayer({
  title: 'Layer_OP_CAT_CAMINO_VEC',
  name: 'F54 ' + Symbolyze_Layer_CAT_CAMINO_VEC,
  type: 'Capa específica',
  source: vectorSource_OP_CAT_CAMINO_VEC,
  style: styleFunction_OP_CAT_CAMINO_VEC,
  zIndex:8
});

global.Map_OP_01 = new LayerGroup({
  title:         'Map_OP_01',
  name:       'Ordenación pormenorizada',
  type:         'Capa específica',
  opacity:    1,
	visible:     false,
  layers:[
    Layer_OP_ENP,
    Layer_OP_LIM_EDIF,
    Layer_OP_ALINEACION,
    Layer_OP_RASANTES,
    Layer_OP_Viario_Eje,
    Layer_OP_Viario_Eje_ANCHO,
    Layer_OP_SG,
    Layer_OP_SG2,
    Layer_OP_DESARROLLO,
    //Layer_OP_MODORD_CONTORNO,
    Layer_OP_MODORD,
    Layer_OP_ZONIFICACION_INFRA,
    Layer_OP_VIARIO,
    Layer_OP_VIARIO_fondo,
    Layer_OP_ZONIFICACION_RES,
    Layer_OP_ZONIFICACION_EL,
    Layer_OP_ZONIFICACION_COM,
    Layer_OP_ZONIFICACION_IND,
    Layer_OP_ZONIFICACION_EQU,
    Layer_OP_ZONIFICACION_TIPOEDIF,
    Layer_OP_CAT_ATQUI,
    Layer_OP_CAT_ETNO,
    Layer_OP_CAT_INDUST,
    Layer_OP_CAT_CAMINO_VEC
  ]
});
////////////////////////////////////////////////////////////////////////

import GeoJSON from 'ol/format/GeoJSON.js';
import {Vector as VectorLayer, Group as LayerGroup} from 'ol/layer.js';
import {Vector as VectorSource} from 'ol/source.js';
import {Fill, Stroke, Style} from 'ol/style.js';



////////////////////////////////////////////////////////////////////////
//CAPA DE USOS DEL SUELO

var GeoJSON_NNSS_Clasificacion_Filter = {
  "type": "FeatureCollection",
  "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::" + DefaultCRS } },
  "features": []
};

for(var i = 0; i<GeoJSON_NNSS_Clasificacion.features.length; i++){
  var feature =GeoJSON_NNSS_Clasificacion.features[i];

  if (feature.properties["Clasificac"] === "Suelo Urbano") {
    GeoJSON_NNSS_Clasificacion_Filter.features.push(feature);
  }
};

var vectorSource_SUL_URB = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_NNSS_Clasificacion_Filter)
});

var styleFunction_SUL_URB = function(feature, resolution) {
  return new  Style({
    stroke: Funct_StrokeStyle[Symbolyze_Layer_SUL_URB]
  })
};

global.Layer_SUL_URB = new VectorLayer({
  title: 'Layer_SUL_URB',
  name: 'F01 ' + Symbolyze_Layer_SUL_URB,
  type: 'Capa específica',
  source: vectorSource_SUL_URB,
  style: styleFunction_SUL_URB,
  zIndex:9
});


var styleFunction_NNSS_Alineaciones = function(feature, resolution) {
  return new  Style({
    stroke: Funct_StrokeStyle[Symbolyze_Layer_NNSS_Alineaciones]
  })
};


var vectorSource_NNSS_Alineaciones = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_NNSS_Alineaciones)
});

global.Layer_NNSS_Alineaciones = new VectorLayer({
  title: 'Layer_NNSS_Alineaciones',
  name: 'F02 ' + Symbolyze_Layer_NNSS_Alineaciones,
  type: 'Capa específica',
  source: vectorSource_NNSS_Alineaciones,
  style: styleFunction_NNSS_Alineaciones,
  zIndex:8
});


var styleFunction_I_Red_viaria = function(feature, resolution) {
  return new  Style({
    stroke: Funct_StrokeStyle[feature.getProperties().JER_Viso]
  })
};


var vectorSource_I_Red_viaria = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_I_Red_viaria)
});

global.Layer_I_Red_viaria = new VectorLayer({
  title: 'Layer_I_Red_viaria',
  name: '03 Jerarquía viaria',
  type: 'Capa específica',
  source: vectorSource_I_Red_viaria,
  style: styleFunction_I_Red_viaria,
  zIndex:7
});


var styleFunction_I_Construcciones = function(feature, resolution) {
  return new  Style({
    fill: Funct_FillStyle[Symbolyze_Layer_I_Construcciones],
    stroke: Funct_StrokeStyle[Symbolyze_Layer_I_Construcciones]
  })
};


var vectorSource_I_Construcciones = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_I_Construcciones)
});

global.Layer_I_Construcciones = new VectorLayer({
  title: 'Layer_I_Construcciones',
  name: 'F04 ' + Symbolyze_Layer_I_Construcciones,
  type: 'Capa específica',
  source: vectorSource_I_Construcciones,
  style: styleFunction_I_Construcciones,
  zIndex:6
});


var styleFunction_I_Uso_Suelo = function(feature, resolution) {
  return new  Style({
    fill: Funct_FillStyle[feature.getProperties().USO_SUELO],
    stroke: Funct_StrokeStyle[feature.getProperties().USO_SUELO]
  })
};


var vectorSource_I_Uso_Suelo = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_I_Uso_Suelo)
});

global.Layer_I_Uso_Suelo = new VectorLayer({
  title: 'Layer_I_Uso_Suelo',
  name: '05 Usos del suelo',
  type: 'Capa específica',
  source: vectorSource_I_Uso_Suelo,
  style: styleFunction_I_Uso_Suelo,
  zIndex:3
});

//Agrupamos todas las capas pertenecientes al mismo plano, para activarlas todas a la misma vez
global.Map_IU_02 = new LayerGroup({
  title:    'Map_IU_02',
  name:     'Usos del suelo',
  type:     'Capa específica',
  opacity:   0.75,
	visible:   false,
  layers:[
    Layer_SUL_URB,
    Layer_NNSS_Alineaciones,
    Layer_I_Red_viaria,
    Layer_I_Construcciones,
    Layer_I_Uso_Suelo
  ]
});
////////////////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////////
//CAPA DE RED VIARIA

var styleFunction_I_Miradores = function(feature, resolution) {
  return new  Style({
    image: Funct_ImageStyle[Symbolyze_Layer_I_Miradores]
  })
};


var vectorSource_I_Miradores = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_I_Mirador)
});

global.Layer_I_Miradores = new VectorLayer({
  title: 'Layer_I_Miradores',
  name: 'F01 ' + Symbolyze_Layer_I_Miradores,
  type: 'Capa específica',
  source: vectorSource_I_Miradores,
  style: styleFunction_I_Miradores,
  zIndex:9
});


var styleFunction_I_Red_viaria = function(feature, resolution) {
  return new  Style({
    stroke: Funct_StrokeStyle[feature.getProperties().Jerarquia_]
  })
};


var vectorSource_I_Red_viaria = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_I_Red_viaria)
});

global.Layer_I_Red_viaria = new VectorLayer({
  title: 'Layer_I_Red_viaria',
  name: '02 Jerarquía viaria',
  type: 'Capa específica',
  source: vectorSource_I_Red_viaria,
  style: styleFunction_I_Red_viaria,
  zIndex:8
});

var styleFunction_I_NNSS_Clasif_IU = function(feature, resolution) {
  return new  Style({
    stroke: Funct_StrokeStyle[feature.getProperties().CLAS_IU],
    fill: Funct_FillStyle[feature.getProperties().CLAS_IU]
  })
};

var GeoJSON_NNSS_Clasificacion_Filter_Null = FiltrarNulosGeoJson(GeoJSON_NNSS_Clasificacion, "CLAS_IU");

var vectorSource_I_NNSS_Clasif_IU = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_NNSS_Clasificacion_Filter_Null)
});

global.Layer_I_NNSS_Clasif_IU1 = new VectorLayer({
  title: 'Layer_I_NNSS_Clasif_IU1',
  name: '09 NNSS Clasificación',
  type: 'Capa específica',
  source: vectorSource_I_NNSS_Clasif_IU,
  style: styleFunction_I_NNSS_Clasif_IU,
  zIndex:3
});

//Agrupamos todas las capas pertenecientes al mismo plano, para activarlas todas a la misma vez
global.Map_IU_031 = new LayerGroup({
  title:    'Map_IU_031',
  name:     'Infraestructuras de comunicación. Red viaria',
  type:     'Capa específica',
  opacity:   0.75,
	visible:   false,
  layers:[
    Layer_I_Miradores,
    Layer_I_Red_viaria,
    Layer_I_NNSS_Clasif_IU1
  ]
});
////////////////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////////
//CAPA DE ABASTECIMIENTO DE AGUA

var styleFunction_I_abast_point = function(feature, resolution) {
  return new  Style({
    image: Funct_ImageStyle[feature.getProperties().Tipo]
  })
};


var vectorSource_I_abast_point = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_Red_abastecimiento_point)
});

global.Layer_I_abast_point = new VectorLayer({
  title: 'Layer_I_abast_point',
  name: '01 Red de abastecimiento',
  type: 'Capa específica',
  source: vectorSource_I_abast_point,
  style: styleFunction_I_abast_point,
  zIndex:9
});


var styleFunction_I_abast_line = function(feature, resolution) {
  return new  Style({
    stroke: Funct_StrokeStyle[Symbolyze_Layer_I_abast_line]
  })
};


var vectorSource_I_abast_line = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_Red_abastecimiento_line)
});

global.Layer_I_abast_line = new VectorLayer({
  title: 'Layer_I_abast_line',
  name: 'F02 ' + Symbolyze_Layer_I_abast_line,
  type: 'Capa específica',
  source: vectorSource_I_abast_line,
  style: styleFunction_I_abast_line,
  zIndex:8
});

global.Layer_I_NNSS_Clasif_IU2 = new VectorLayer({
  title: 'Layer_I_NNSS_Clasif_IU2',
  name: '09 NNSS Clasificación',
  type: 'Capa específica',
  source: vectorSource_I_NNSS_Clasif_IU,
  style: styleFunction_I_NNSS_Clasif_IU,
  zIndex:3
});

//Agrupamos todas las capas pertenecientes al mismo plano, para activarlas todas a la misma vez
global.Map_IU_032 = new LayerGroup({
  title:    'Map_IU_032',
  name:     'Infraestructuras de abastecimiento de agua',
  type:     'Capa específica',
  opacity:   0.75,
	visible:   false,
  layers:[
    Layer_I_abast_point,
    Layer_I_abast_line,
    Layer_I_NNSS_Clasif_IU2
  ]
});
////////////////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////////
//CAPA DE SANEAMIENTO

var styleFunction_I_sanea_point = function(feature, resolution) {
  return new  Style({
    image: Funct_ImageStyle[feature.getProperties().TIPO]
  })
};


var vectorSource_I_sanea_point = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_Red_saneamiento_point)
});

global.Layer_I_sanea_point = new VectorLayer({
  title: 'Layer_I_sanea_point',
  name: '01 Red de saneamiento',
  type: 'Capa específica',
  source: vectorSource_I_sanea_point,
  style: styleFunction_I_sanea_point,
  zIndex:9
});


var styleFunction_I_sanea_line = function(feature, resolution) {
  return new  Style({
    stroke: Funct_StrokeStyle[Symbolyze_Layer_I_sanea_line]
  })
};


var vectorSource_I_sanea_line = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_Red_saneamiento_line)
});

global.Layer_I_sanea_line = new VectorLayer({
  title: 'Layer_I_sanea_line',
  name: 'F02 ' + Symbolyze_Layer_I_sanea_line,
  type: 'Capa específica',
  source: vectorSource_I_sanea_line,
  style: styleFunction_I_sanea_line,
  zIndex:8
});

global.Layer_I_NNSS_Clasif_IU3 = new VectorLayer({
  title: 'Layer_I_NNSS_Clasif_IU3',
  name: '09 NNSS Clasificación',
  type: 'Capa específica',
  source: vectorSource_I_NNSS_Clasif_IU,
  style: styleFunction_I_NNSS_Clasif_IU,
  zIndex:3
});

//Agrupamos todas las capas pertenecientes al mismo plano, para activarlas todas a la misma vez
global.Map_IU_033 = new LayerGroup({
  title:    'Map_IU_033',
  name:     'Infraestructuras de saneamiento y depuración de aguas',
  type:     'Capa específica',
  opacity:   0.75,
	visible:   false,
  layers:[
    Layer_I_sanea_point,
    Layer_I_sanea_line,
    Layer_I_NNSS_Clasif_IU3
  ]
});
////////////////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////////
//CAPA DE RED ELÉCTRICA, ALUMBRADO Y TELECOMUNICACIONES

var styleFunction_I_luminaria = function(feature, resolution) {
  return new  Style({
    image: Funct_ImageStyle[feature.getProperties().TIPO]
  })
};


var vectorSource_I_luminaria = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_Red_alumbrado)
});

global.Layer_I_luminaria = new VectorLayer({
  title: 'Layer_I_luminaria',
  name: '01 Red de alumbrado',
  type: 'Capa específica',
  source: vectorSource_I_luminaria,
  style: styleFunction_I_luminaria,
  zIndex:9
});


var styleFunction_I_teleco_point = function(feature, resolution) {
  return new  Style({
    image: Funct_ImageStyle[feature.getProperties().Tipo]
  })
};


var vectorSource_I_teleco_point = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_Red_teleco_point)
});

global.Layer_I_teleco_point = new VectorLayer({
  title: 'Layer_I_teleco_point',
  name: '02 Red de telecomunicaciones',
  type: 'Capa específica',
  source: vectorSource_I_teleco_point,
  style: styleFunction_I_teleco_point,
  zIndex:9
});


var styleFunction_I_elect_point = function(feature, resolution) {
  return new  Style({
    image: Funct_ImageStyle[feature.getProperties().Tipo]
  })
};


var vectorSource_I_elect_point = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_Red_elect_point)
});

global.Layer_I_elect_point = new VectorLayer({
  title: 'Layer_I_elect_point',
  name: '03 Red de suministro eléctrico',
  type: 'Capa específica',
  source: vectorSource_I_elect_point,
  style: styleFunction_I_elect_point,
  zIndex:9
});


var styleFunction_I_elect_line = function(feature, resolution) {
  return new  Style({
    stroke: Funct_StrokeStyle[feature.getProperties().Layer]
  })
};


var vectorSource_I_elect_line = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_Red_elect_line)
});

global.Layer_I_elect_line = new VectorLayer({
  title: 'Layer_I_elect_line',
  name: '04 Red de suministro  eléctrico',
  type: 'Capa específica',
  source: vectorSource_I_elect_line,
  style: styleFunction_I_elect_line,
  zIndex:8
});

global.Layer_I_NNSS_Clasif_IU4 = new VectorLayer({
  title: 'Layer_I_NNSS_Clasif_IU4',
  name: '09 NNSS Clasificación',
  type: 'Capa específica',
  source: vectorSource_I_NNSS_Clasif_IU,
  style: styleFunction_I_NNSS_Clasif_IU,
  zIndex:3
});

//Agrupamos todas las capas pertenecientes al mismo plano, para activarlas todas a la misma vez
global.Map_IU_034 = new LayerGroup({
  title:    'Map_IU_034',
  name:     'Infraestructuras de energía eléctrica, alumbrado y telecomunicaciones',
  type:     'Capa específica',
  opacity:   0.75,
	visible:   false,
  layers:[
    Layer_I_luminaria,
    Layer_I_teleco_point,
    Layer_I_elect_point,
    Layer_I_elect_line,
    Layer_I_NNSS_Clasif_IU4
  ]
});
////////////////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////////
//CAPA DE ABASTECIMIENTO DE AGUA

var styleFunction_I_resid_point = function(feature, resolution) {
  return new  Style({
    image: Funct_ImageStyle[feature.getProperties().Tipo]
  })
};


var vectorSource_I_resid_point = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_Red_resid_point)
});

global.Layer_I_resid_point = new VectorLayer({
  title: 'Layer_I_resid_point',
  name: '01 Red de residuos urbanos',
  type: 'Capa específica',
  source: vectorSource_I_resid_point,
  style: styleFunction_I_resid_point,
  zIndex:9
});

global.Layer_I_NNSS_Clasif_IU5 = new VectorLayer({
  title: 'Layer_I_NNSS_Clasif_IU5',
  name: '09 NNSS Clasificación',
  type: 'Capa específica',
  source: vectorSource_I_NNSS_Clasif_IU,
  style: styleFunction_I_NNSS_Clasif_IU,
  zIndex:3
});

//Agrupamos todas las capas pertenecientes al mismo plano, para activarlas todas a la misma vez
global.Map_IU_035 = new LayerGroup({
  title:    'Map_IU_035',
  name:     'Infraestructuras de gestión de residuos',
  type:     'Capa específica',
  opacity:   0.75,
	visible:   false,
  layers:[
    Layer_I_resid_point,
    Layer_I_NNSS_Clasif_IU5
  ]
});
////////////////////////////////////////////////////////////////////////

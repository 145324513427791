import GeoJSON from 'ol/format/GeoJSON.js';
import {Vector as VectorLayer, Group as LayerGroup} from 'ol/layer.js';
import {Vector as VectorSource} from 'ol/source.js';
import {Fill, Stroke, Style} from 'ol/style.js';


////////////////////////////////////////////////////////////////////////
//CAPA DE DENSIDAD DE POBLACIÓN
//CAPA DENSIDAD MUNICIPAL POR ENTIDADES DE POBLACIÓN
var styleFunction_DENS_POB_nucleo_pob_MUNI = function(feature, resolution) {
  return new  Style({
    fill: FunctionColorRellenoGradual(feature.getProperties().DENSIDAD_M, Funct_FillStyle[Symbolyze_Layer_DENS_POB_nucleo_pob_MUNI]),
    stroke: Funct_StrokeStyle[Symbolyze_Layer_DENS_POB_nucleo_pob_MUNI]
  })
};


var vectorSource_DENS_POB_nucleo_pob_MUNI = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_DENS_POB_nucleo_pob)
});

global.Layer_DENS_POB_nucleo_pob_MUNI = new VectorLayer({
  title: 'Layer_DENS_POB_nucleo_pob_MUNI',
  name: 'GD ' + Symbolyze_Layer_DENS_POB_nucleo_pob_MUNI,
  type: 'Capa específica',
  source: vectorSource_DENS_POB_nucleo_pob_MUNI,
  style: styleFunction_DENS_POB_nucleo_pob_MUNI,
  zIndex:3
});

//Agrupamos todas las capas pertenecientes al mismo plano, para activarlas todas a la misma vez
global.Map_SE_01a = new LayerGroup({
  title:    'Map_SE_01a',
  name:     'Densidad de población municipal - Entidades de población',
  type:     'Capa específica',
  opacity:   0.75,
	visible:   false,
  layers:[
    Layer_DENS_POB_nucleo_pob_MUNI
  ]
});
////////////////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////////
//CAPA DENSIDAD POR ENTIDADES DE POBLACIÓN
var styleFunction_DENS_POB_nucleo_pob_ENTI = function(feature, resolution) {
  return new  Style({
    fill: FunctionColorRellenoGradual(feature.getProperties().DENSIDAD, Funct_FillStyle[Symbolyze_Layer_DENS_POB_nucleo_pob_ENTI]),
    stroke: Funct_StrokeStyle[Symbolyze_Layer_DENS_POB_nucleo_pob_ENTI]
  })
};


var vectorSource_DENS_POB_nucleo_pob_ENTI = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_DENS_POB_nucleo_pob)
});

global.Layer_DENS_POB_nucleo_pob_ENTI = new VectorLayer({
  title: 'Layer_DENS_POB_nucleo_pob_ENTI',
  name: 'GD ' + Symbolyze_Layer_DENS_POB_nucleo_pob_ENTI,
  type: 'Capa específica',
  source: vectorSource_DENS_POB_nucleo_pob_ENTI,
  style: styleFunction_DENS_POB_nucleo_pob_ENTI,
  zIndex:3
});

//Agrupamos todas las capas pertenecientes al mismo plano, para activarlas todas a la misma vez
global.Map_SE_01b = new LayerGroup({
  title:    'Map_SE_01b',
  name:     'Densidad de población - Entidades de población',
  type:     'Capa específica',
  opacity:   0.75,
	visible:   false,
  layers:[
    Layer_DENS_POB_nucleo_pob_ENTI
  ]
});
////////////////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////////
//CAPA DE SECTORES ECONÓMICOS

var styleFunction_ExplAgricola = function(feature, resolution) {
  return new  Style({
    fill: Funct_FillStyle[feature.getProperties().ESTADO],
    stroke: Funct_StrokeStyle[feature.getProperties().ESTADO]
  })
};


var vectorSource_ExplAgricola = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_ExplAgricola)
});

global.Layer_ExplAgricola = new VectorLayer({
  title: 'Layer_ExplAgricola',
  name: '01 Sector primerio',
  type: 'Capa específica',
  source: vectorSource_ExplAgricola,
  style: styleFunction_ExplAgricola,
  zIndex:3
});


var styleFunction_ExplGanadera = function(feature, resolution) {
  return new  Style({
    fill: Funct_FillStyle[feature.getProperties().LAYER_USO],
    stroke: Funct_StrokeStyle[feature.getProperties().LAYER_USO]
  })
};


var vectorSource_ExplGanadera = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_ExplGanadera)
});

global.Layer_ExplGanadera = new VectorLayer({
  title: 'Layer_ExplGanadera',
  name: '01 Sector primerio',
  type: 'Capa específica',
  source: vectorSource_ExplGanadera,
  style: styleFunction_ExplGanadera,
  zIndex:3
});


var styleFunction_Industria = function(feature, resolution) {
  return new  Style({
    fill: Funct_FillStyle[feature.getProperties().LAYER_USO],
    stroke: Funct_StrokeStyle[feature.getProperties().LAYER_USO]
  })
};


var vectorSource_Industria = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_Industria)
});

global.Layer_Industria = new VectorLayer({
  title: 'Layer_Industria',
  name: '02 Sector secundario',
  type: 'Capa específica',
  source: vectorSource_Industria,
  style: styleFunction_Industria,
  zIndex:3
});


var styleFunction_SERVICIOS = function(feature, resolution) {
  return new  Style({
    fill: Funct_FillStyle[feature.getProperties().LAYER_ACTI],
    stroke: Funct_StrokeStyle[feature.getProperties().LAYER_ACTI]
  })
};


var vectorSource_SERVICIOS = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_SERVICIOS)
});

global.Layer_SERVICIOS = new VectorLayer({
  title: 'Layer_SERVICIOS',
  name: '03 Sector terciario',
  type: 'Capa específica',
  source: vectorSource_SERVICIOS,
  style: styleFunction_SERVICIOS,
  zIndex:6
});

//Agrupamos todas las capas pertenecientes al mismo plano, para activarlas todas a la misma vez
global.Map_SE_02 = new LayerGroup({
  title:    'Map_SE_02',
  name:     'Sectores económicos',
  type:     'Capa específica',
  opacity:   0.75,
	visible:   false,
  layers:[
    Layer_ExplAgricola,
    Layer_ExplGanadera,
    Layer_Industria,
    Layer_SERVICIOS
  ]
});
////////////////////////////////////////////////////////////////////////

import GeoJSON from 'ol/format/GeoJSON.js';
import {Vector as VectorLayer, Group as LayerGroup} from 'ol/layer.js';
import {Vector as VectorSource} from 'ol/source.js';
import {Fill, Stroke, Style} from 'ol/style.js';



////////////////////////////////////////////////////////////////////////
//CAPA DE CENSO DE VIVIENDAS NO AMPARADAS
var styleFunction_I_Censo = function(feature, resolution) {
  var ValorSimbolizacion = Leyenda_I_Censo[feature.getProperties().LOC][1];
  var ParametrosLabel = Funct_Label["Layer_I_Censo"];

  return new  Style({
    stroke: Funct_StrokeStyle[ValorSimbolizacion],
    fill: Funct_FillStyle[ValorSimbolizacion],
    text: createTextStyle(feature, resolution, ParametrosLabel[0],FunctLabelText(feature, ParametrosLabel[1]), ParametrosLabel[2], ParametrosLabel[3], ParametrosLabel[4], ParametrosLabel[5], ParametrosLabel[6], ParametrosLabel[7], ParametrosLabel[8], ParametrosLabel[9], ParametrosLabel[10], ParametrosLabel[11], ParametrosLabel[12], ParametrosLabel[13], ParametrosLabel[14], ParametrosLabel[15]),
  })
};

var GeoJSON_I_CENSO_Filter = {
  "type": "FeatureCollection",
  "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::" + DefaultCRS } },
  "features": []
};

for(var i = 0; i<GeoJSON_I_CENSO.features.length; i++){
  var feature =GeoJSON_I_CENSO.features[i];

  if ((feature.properties["LOC"] == "Localización precisa")) {
    GeoJSON_I_CENSO_Filter.features.push(feature);
  }
};

var vectorSource_I_Censo = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_I_CENSO_Filter)
});

global.Layer_I_Censo = new VectorLayer({
  title: 'Layer_I_Censo',
  name:  'Censo de edificaciones no amparadas por licencia',
  type: 'Capa específica',
  source: vectorSource_I_Censo,
  style: styleFunction_I_Censo,
  zIndex:3
});

var styleFunction_I_Censo_LOCIMPR = function(feature, resolution) {
  var ParametrosLabel = Funct_Label["Layer_I_Censo_LOCIMPR"];

  return new  Style({
    image: Funct_ImageStyle[Symbolyze_Layer_I_Censo_LOCIMPR],
    text: createTextStyle(feature, resolution, ParametrosLabel[0],FunctLabelText(feature, ParametrosLabel[1]), ParametrosLabel[2], ParametrosLabel[3], ParametrosLabel[4], ParametrosLabel[5], ParametrosLabel[6], ParametrosLabel[7], ParametrosLabel[8], ParametrosLabel[9], ParametrosLabel[10], ParametrosLabel[11], ParametrosLabel[12], ParametrosLabel[13], ParametrosLabel[14], ParametrosLabel[15]),
  })
};

var vectorSource_I_Censo_LOCIMPR = new VectorSource({
  features: (new GeoJSON()).readFeatures(GeoJSON_I_CENSO_LOCIMPR)
});

global.Layer_I_Censo_LOCIMPR = new VectorLayer({
  title: 'Layer_I_Censo_LOCIMPR',
  name: 'F02 ' + Symbolyze_Layer_I_Censo_LOCIMPR,
  type: 'Capa específica',
  source: vectorSource_I_Censo_LOCIMPR,
  style: styleFunction_I_Censo_LOCIMPR,
  zIndex:3
});

//Agrupamos todas las capas pertenecientes al mismo plano, para activarlas todas a la misma vez
global.Map_INFANX_08 = new LayerGroup({
  title:    'Map_INFANX_08',
  name:     'Edificaciones censadas no amparadas por el planeamiento',
  type:     'Capa específica',
  opacity:   0.75,
	visible:   false,
  layers:[
    Layer_I_Censo,
    Layer_I_Censo_LOCIMPR
  ]
});
////////////////////////////////////////////////////////////////////////